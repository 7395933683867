import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import AppLayout from "./components/Layout/Layout";
import { getUserAuthToken } from "./helpers/userDetails";
import LoginPage from "./pages/LoginPage";
import PolicyPage from "./privacy-policy/PrivacyPolicyPage";
import LoginComponentToDeleteAccount from "./components/deleteAccountModule/LoginToDeleteAccount";
import DeleteAccountPage from "./components/deleteAccountModule/DeleteAccount";
import SuperAdminLoginPage from "./pages/SuperAdminLoginPage";
import DocumentViewerPrivacyPolicyPage from "./privacy-policy/DocumentViewerPrivacyPolicyPage";
import PhotoCollageMakerPrivacyPolicyPage from "./privacy-policy/PhotoCollageMakerPrivacyPolicyPage";
import ZipUnzipAppPrivacyPolicyPage from "./privacy-policy/ZipUnzipAppPrivacyPolicyPage";
import SpeechToTextConverterPrivacyPolicyPage from "./privacy-policy/SpeechToTextConverterPrivacyPolicyPage";
import LogoMakerPrivacyPolicyPage from "./privacy-policy/LogoMakerPrivacyPolicyPage";
import CamScannerPdfConverterPrivacyPolicyPage from "./privacy-policy/CamScannerPdfConverterPrivacyPolicyPage";

const RoutesWrapper = () => {
  const isLoggedInForAccountDelete =
    localStorage.getItem("authTokenToDeleteAccount") || false;
  const isLoggedIn = getUserAuthToken() !== "" ? true : false; //localStorage.getItem("auth_token") || false;
  // const isLoggedIn = true;
  const getHomePage = () => {
    if (isLoggedIn === true) {
      return <></>;
    }
    return <LoginPage />;
  };

  return (
    <Router>
      {isLoggedIn && <AppLayout />}
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login-super-admin" element={<SuperAdminLoginPage />} />
        {/* <Route path="/forgot-password" element={<ForgotPasswordPage />} /> */}
        {/* <Route path="/forgot-password-otp" element={<OTPInputPage />} /> */}
        <Route path="/" element={getHomePage()} />
        <Route path="/privacy-policy" element={<PolicyPage />} />
        <Route
          path="/privacy-policy-document-viewer"
          element={<DocumentViewerPrivacyPolicyPage />}
        />
        <Route
          path="/privacy-policy-photo-collage-maker"
          element={<PhotoCollageMakerPrivacyPolicyPage />}
        />
        <Route
          path="/privacy-policy-zip-unzip-app"
          element={<ZipUnzipAppPrivacyPolicyPage />}
        />
        <Route
          path="/privacy-policy-speech-to-text-converter"
          element={<SpeechToTextConverterPrivacyPolicyPage />}
        />
        <Route
          path="/privacy-policy-logo-maker-app"
          element={<LogoMakerPrivacyPolicyPage />}
        />

        <Route
          path="/privacy-policy-cam-scanner-app"
          element={<CamScannerPdfConverterPrivacyPolicyPage />}
        />

        <Route
          path="/login-to-delete-account"
          element={<LoginComponentToDeleteAccount />}
        />
        {isLoggedInForAccountDelete !== false ? (
          <Route path="/delete-account" element={<DeleteAccountPage />} />
        ) : null}
      </Routes>
    </Router>
  );
};

export default RoutesWrapper;
